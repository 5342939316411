
import { defineComponent, onMounted, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { SearchData } from '@/models/search.model';
import {
  CategoriesJuranet,
  CategoriesPrassiJuranet,
  CategoriesGiurisprudenza,
  CategoriesNormativa,
  OrderingTypes,
} from '@/models/enums.model';
import * as eventBus from '../../services/event.bus.js';
import JnDoc from '../../components/DocCard.vue';
import { Document } from '@/models/document.model';
import { EsService } from '@/services/es.service';
import { document } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { SearchTypes } from '@/models/enums.model';

addIcons({
  document: document,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonTabButton,
  IonTabs,
  IonTabBar,
  IonToolbar,
  IonItem,
  IonList,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonLoading,
  alertController,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnRealTime',
  computed: {
    ...mapState(['studio', 'utente']),
  },
  props: {},
  components: {
    IonPage,
    /*IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuToggle,
    IonTitle,
    IonMenuButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText,*/
    IonList,
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    IonContent,
    IonHeader,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    JnDoc,
    IonLoading,
  },

  setup(props) {
    const store = useStore();
    const isDisabled = ref(false);
    const docs = ref([] as Document[]);
    const docsSize = ref();

    const loading = ref(true);
    const timeout = ref(5000);

    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANET_INDEXES,
      from: 0,
      idCategory: CategoriesNormativa,
      size: 10,
      orderingType: OrderingTypes.DATA_GAZZETTA,
      searchInMassime: false
    });

    const showAlert = async (message: string) => {
      const alert = await alertController.create({
        header: 'Errore',
        message: message,
        buttons: [
          {
            text: 'Riprova',
            role: 'retry',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        await showAlert(err.message);
      }
    };

    const pushDocuments = async () => {
      searchData.from = docs.value.length;
      const newDocs = await EsService.getDocs(searchData);
      for (let i = 0; i < newDocs.length; i++) {
        docs.value.push(newDocs[i]);
      }
    };

    onMounted(async () => {
      loading.value = true;
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        loading.value = false;
        await showAlert(err.message);
      }
      loading.value = false;
      eventBus.default.$on('reset-results', function(data: string) {
        docs.value = [];
        pushDocuments();
      });
    });

    const loadDocuments = async (ev: CustomEvent) => {
      setTimeout(() => {
        pushDocuments();
        const target = ev.target as HTMLIonInfiniteScrollElement;
        target.complete();
        if (docs.value.length == docsSize.value) {
          target.disabled = true;
        }
      }, 500);
    };

    pushDocuments();

    const changeType = async (ev: CustomEvent) => {
      loading.value = true;
      docs.value = [];
      searchData.from = 0;
      if (ev.detail.value == '1') {
        searchData.idCategory = CategoriesNormativa;
        searchData.orderingType = OrderingTypes.DATA_GAZZETTA;
      } else if (ev.detail.value == '2') {
        searchData.idCategory = CategoriesGiurisprudenza;
        searchData.orderingType = OrderingTypes.DATA_CREAZIONE;
      } else if (ev.detail.value == '3') {
        searchData.idCategory = CategoriesPrassiJuranet;
        searchData.orderingType = OrderingTypes.DATA_CREAZIONE;
      }
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        loading.value = false;
        await showAlert(err.message);
      }
      loading.value = false;
    };

    return {
      isDisabled,
      docs,
      loadDocuments,
      changeType,
      loading,
      timeout,
    };
  },
});
